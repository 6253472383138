import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const StyledBanner = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  color: white;
  position: relative;
  padding: 10px 0;
`;
const StyledButton = styled.button`
  border: none;
  background: transparent;
  color: white;
  position: absolute;
  top: 10px;
  right: 10px;
`;
const StyledLink = styled.div`
  display: inline-flex;
  align-items: center;
`;
const closeBanner = () => {
  document.getElementById("banner").style.display = "none";
};

const BlackFridaySale = () => {
  return (
    <>
      <StyledBanner id="banner">
        <span className="flex-1">
          Black Friday Sale: Get <strong>30% OFF</strong> on all plans using the
          code <strong>BLACKFRIDAY2024</strong>
        </span>

        <Link
          className="text-white"
          to={process.env.SIGNUP_URL}
          target="__blank"
        >
          <StyledLink>
            {` . Upgrade now`}
            <i className="ml-2 icon icon-tail-right font-weight-bold"></i>
          </StyledLink>
        </Link>

        <StyledButton onClick={closeBanner}>X</StyledButton>
      </StyledBanner>
    </>
  );
};

export default BlackFridaySale;
